<script lang="ts">
  import { getContext } from "svelte";
  import { mode } from "../../stores";
  import { modeData } from "../../utils";
  import GameIcon from "../GameIcon.svelte";
  import type Toaster from "./Toaster.svelte";

  export let wordNumber: number;

  const toaster: Toaster = getContext("toaster");

  function share() {
    toaster.pop("Copied");
    navigator.clipboard.writeText(`${window.location.href}/${wordNumber}`);
  }
</script>

<style>
  div {
    color: var(--fg-secondary);
    font-size: var(--fs-regular);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
</style>
