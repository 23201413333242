<script lang="ts">
  import { Tile } from "../board";
  export let visible: boolean;
</script>

<h3>i ❤️ shadyar</h3>
<div>
  I can't draw cartoons, and I haven't mastered the crafting techniques of the
  Huichol people. But I can make things on the internet.
</div>
<div>
  And I was very pleased to find out that the domain iloveshadyar.com was still
  available!
</div>
<div>
  I can't believe it's only been a year. You are my favourite person in the
  world and it bums me out thinking about all the years I spent without you in
  my life.
</div>
<div class:complete={visible} class="examples">
  <div><strong>Examples</strong></div>
  <div class="row">
    <Tile value="s" state="🟩" />
    <Tile value="h" state="🔳" />
    <Tile value="a" state="🔳" />
    <Tile value="d" state="🔳" />
    <Tile value="y" state="🔳" />
  </div>
  <div>
    The letter <strong>S</strong> is in the word and in the correct spot.
  </div>
  <div class="row">
    <Tile value="b" state="🔳" />
    <Tile value="r" state="🟨" />
    <Tile value="a" state="🔳" />
    <Tile value="i" state="🔳" />
    <Tile value="n" state="🔳" />
  </div>
  <div>The letter <strong>R</strong> is in the word but in the wrong spot.</div>
</div>

<style lang="scss">
  div {
    margin: 14px 0;
  }
  .examples {
    border-top: 1px solid var(--border-primary);
    border-bottom: 1px solid var(--border-primary);
    :global(.row > *) {
      height: 100%;
      aspect-ratio: 1;
    }
    &:not(.complete) :global(.row .back) {
      transition-delay: 0.3s;
    }
  }
  .row {
    height: 40px;
    display: flex;
    gap: 4px;
  }
</style>
